@import "../../../../styles/base/breakpoints";

// Grow Business
.containerStyle {
  padding: 50px 0 150px 0;
  background-color: #f6f9fa;

  .content {
    margin: 35px 0;

    @include respond-min($lg) {
      padding: 0 30px;
    }

    @include respond-max($lg) {
      margin: 15px 0;
    }

    img {
      margin-bottom: 25px;
      width: 70px;
    }

    h3 {
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 12px;
      line-height: 35px;
    }
  }
}
