.searchBox {
  height: 32px;
  padding: 5px;
  width: 220px;
  border: 1px solid #c2c2c2;
  border-radius: 3px;

  &:focus {
    outline: none;
  }
}
